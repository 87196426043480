export function handleUTMSource() {
    // Function to extract parameter from URL
    function getParameterByName(name) {
        let value = "";

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has(name)) {
            value = urlParams.get(name);
        } else {
            const cookies = document.cookie.split(';');
            cookies.forEach(cookie => {
                const [cookieName, cookieValue] = cookie.split('=').map(c => c.trim());
                if (cookieName === name) {
                    value = cookieValue;
                }
            });
        }

        return value;
    }

    // Function to set UTM source, medium, and campaign values in localStorage and hidden form fields
    const setUTMParameters = () => {
        const utmSource = getParameterByName("utm_source") || getCookie("utm_source");
        const utmMedium = getParameterByName("utm_medium") || getCookie("utm_medium");
        const utmCampaign = getParameterByName("utm_campaign") || getCookie("utm_campaign");
        const utmElements = document.querySelectorAll("form input[type=\"hidden\"]");
        const referrer = document.referrer.toLowerCase();
        const hours = 700;
        // If UTM parameters are missing, use the referrer to determine the source
        if (!utmSource) {

            if (referrer.includes("google.")) {
                setCookiesAndFields("google", "organic", null);
            } else if (referrer.includes("bing.com")) {
                setCookiesAndFields("bing", "organic", null);
            } else if (!referrer) {
                setCookiesAndFields("direct", "none", null);
            }
        } else {
            // If UTM parameters exist, save them to cookies and populate hidden fields
            if (referrer && !referrer.includes('direct')) {
                if (referrer.includes('google.') && utmSource !== 'google') {
                    setCookiesAndFields('google', 'organic', null);
                } else if (referrer.includes('bing.com') && utmSource !== 'bing') {
                    setCookiesAndFields('bing', 'organic', null);
                }
            }else{
                setCookiesAndFields(utmSource, utmMedium, utmCampaign);
            }
        }
        function setCookiesAndFields(source, medium, campaign) {
            if (source) {
                document.cookie = `utm_source=${source}; expires=${getCookieExpirationTime(hours)}; path=/`;
            }
            if (medium) {
                document.cookie = `utm_medium=${medium}; expires=${getCookieExpirationTime(hours)}; path=/`;
            }
            if (campaign) {
                document.cookie = `utm_campaign=${campaign}; expires=${getCookieExpirationTime(hours)}; path=/`;
            }

            utmElements.forEach(element => {
                if (element.name === "utm_source" && source) {
                    element.value = source;
                } else if (element.name === "utm_medium" && medium) {
                    element.value = medium;
                } else if (element.name === "utm_campaign" && campaign) {
                    element.value = campaign;
                }
            });
        }
    }

    // Function to get the value of a cookie
    function getCookie(name) {
        const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const [key, value] = cookie.split("=");
            if (key === name) {
                return decodeURIComponent(value);
            }
        }
        return null;
    }


    // Function to calculate cookie expiration time
    function getCookieExpirationTime(hours) {
        const currentTime = new Date();
        const expirationTime = new Date(currentTime.getTime() + hours * 60 * 60 * 1000);
        return expirationTime.toUTCString();
    }



    // Call setUTMParameters when DOMContentLoaded event fires
    document.addEventListener("DOMContentLoaded", function () {
        setUTMParameters();
    });
}